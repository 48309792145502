import React from 'react';
// State management
import { useRecoilState } from 'recoil';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import AppIcons from '../../assets/images/icons/index';
import SVGIcons from '../../assets/images/icons/svgIcons';

import '../../index.scss';
import { myPortalState } from '../../state/reducerAtoms/myPortal.atom';
import LogoutButton from '../buttons/logout.button';
import ThemeSwitchButton from '../buttons/themeSwitch.button';
import { EMPLOYEE_ROLE, STUDENT_ROLE, UNIVERSITY_ADMIN_ROLE, UNIVERSITY_ROLE } from '../../constants/index';
import { accessFiltered, getAuthRoleName } from '../../helpers/auth.helper';
import { translateThis } from '../../helpers/language.helper';
import { getCurrentFacilityURL } from '../../helpers/helper.functions';
import ProviderLogo from '../logo/facilityLogo';




const drawerWidth = 70;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    [theme.breakpoints.up('xs')]: {
        width: '90%',
    },
    [theme.breakpoints.up('sm')]: {
        width: 70,
    },
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(8)} + 6px)`,
    [theme.breakpoints.up('xs')]: {
        width: 0,
    },
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 6px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const UserDrawer = () => {
    const theme = useTheme();
    const [myPortalInitialState, setMyPortalState] = useRecoilState(myPortalState);
    const facilityURL = getCurrentFacilityURL()


    /**
     * Invokes when drawer open
     * @returns Fun
     */
    const handleDrawerOpen = () => setMyPortalState((prv) => ({ ...prv, ...{ isDrawerOpen: true } }));

    /**
     * Invokes when drawer close
     * @returns Fun
     */
    const handleDrawerClose = () => setMyPortalState((prv) => ({ ...prv, ...{ isDrawerOpen: false } }));

    /**
     * Invokes when user click a nav item
     * Passes the selected nav to my portal
     * @param {Object} item selected nav item
     */
    const onSelectNavItem = (item) => setMyPortalState((prv) => ({ ...prv, ...{ selectedNavItem: item.id, pageHeader: item.pageHeader } }))

    const newFeaturesCreateMenu = [
        {
            title: translateThis("Create"),
            className: 'question',
            id: 'question',
            accessId: 'create',
            featureName: 'question',
            allowOnly: [UNIVERSITY_ROLE, UNIVERSITY_ADMIN_ROLE],
            icon: SVGIcons.CreateFileIcon(),
            link: `/${facilityURL}/create-new-item`
        },
        {
            title: translateThis('Create a new practice'),
            pcTitle: translateThis('Practice'),
            className: 'question',
            id: 'question',
            accessId: 'question',
            featureName: 'question',
            allowOnly: [STUDENT_ROLE],
            icon: SVGIcons.CreateFileIcon(),
            link: `/${facilityURL}/create-practice`
        },

    ].filter((item) => item.allowOnly.includes(getAuthRoleName()));
    const myNavItems = [
        {
            title: translateThis("home"),
            pageHeader: translateThis("home"),
            id: 'home',
            accessId: 'home',
            allowOnly: [UNIVERSITY_ROLE, UNIVERSITY_ADMIN_ROLE, EMPLOYEE_ROLE, STUDENT_ROLE],
            featureName: 'home',
            icon: SVGIcons.HomeIcon(),
            link: `/${facilityURL}/my-portal`
        },
        {
            title: translateThis("Practices"),
            pageHeader: translateThis("Practices"),
            id: 'previousTest',
            allowOnly: [STUDENT_ROLE],
            featureName: 'tests',
            icon: SVGIcons.TestIcon(),
            link: `/${facilityURL}/my-practices`
        },
        {
            title: translateThis("Exams"),
            pageHeader: translateThis("Exams"),
            id: 'exams',
            allowOnly: [STUDENT_ROLE],
            featureName: 'exams',
            icon: SVGIcons.TestIcon(),
            link: `/${facilityURL}/exams`
        },
        {
            title: translateThis("manage"),
            pageHeader: translateThis("manage"),
            id: 'manage',
            accessId: 'manage',
            allowOnly: [UNIVERSITY_ROLE, UNIVERSITY_ADMIN_ROLE],
            featureName: 'manage',
            icon: SVGIcons.BlockIcon(),
            link: `/${facilityURL}/manage`
        },
        {
            title: translateThis("Exams"),
            pageHeader: translateThis("Exams"),
            id: 'exams',
            accessId: 'exams',
            allowOnly: [UNIVERSITY_ROLE, UNIVERSITY_ADMIN_ROLE],
            featureName: 'exams',
            icon: SVGIcons.TestIcon(),
            link: `/${facilityURL}/exam-logs`
        },
        {
            title: translateThis("users"),
            pageHeader: translateThis("users"),
            id: 'users',
            accessId: 'users',
            allowOnly: [UNIVERSITY_ROLE, UNIVERSITY_ADMIN_ROLE],
            featureName: 'users',
            icon: SVGIcons.UserIcon(),
            link: `/${facilityURL}/users`
        },
        {
            title: translateThis("stats"),
            pageHeader: translateThis("stats"),
            id: 'stats',
            allowOnly: [STUDENT_ROLE],
            featureName: 'stats',
            icon: SVGIcons.StatsIcon(),
            link: `/${facilityURL}/stats`
        },
        {
            title: translateThis("Classes"),
            pageHeader: translateThis("Classes"),
            id: 'classes',
            allowOnly: [STUDENT_ROLE],
            featureName: 'classes',
            icon: SVGIcons.ClassIcon(),
            link: `/${facilityURL}/classes`
        },
        {
            title: translateThis("Messages"),
            pageHeader: translateThis("Messages"),
            id: 'inbox',
            accessId: 'messages',
            allowOnly: [UNIVERSITY_ROLE, UNIVERSITY_ADMIN_ROLE, STUDENT_ROLE],
            featureName: 'inbox',
            icon: SVGIcons.InboxIcon(),
            link: `/${facilityURL}/inbox`
        },
        // {
        //     title: translateThis("graphs"),
        //     pageHeader: translateThis("graphs"),
        //     id: 'graphs',
        //     allowOnly: [STUDENT_ROLE],
        //     featureName: 'graphs',
        //     icon: SVGIcons.GraphsIcon(),
        //     link: `/${facilityURL}/graphs`
        // }
        // ,
        // {
        //     title: translateThis("bulletin"),
        //     pageHeader: translateThis("bulletin"),
        //     id: 'bulletin',
        //     // allowOnly: [UNIVERSITY_ROLE, EMPLOYEE_ROLE],
        //     featureName: 'bulletin',
        //     icon: SVGIcons.BulletIcon(),
        //     link: `/${facilityURL}/bulletin`
        // },
        // {
        //     title: translateThis("Policies"),
        //     pageHeader: translateThis("Policies"),
        //     id: 'policies',
        //     // allowOnly: [UNIVERSITY_ROLE, EMPLOYEE_ROLE],
        //     featureName: 'policies',
        //     icon: SVGIcons.GuardIcon(),
        //     link: `/${facilityURL}/policies`
        // },
        // {
        //     title: translateThis("Home"),
        //     pageHeader: translateThis("Home"),
        //     id: 'Home',
        //     // allowOnly: [UNIVERSITY_ROLE, EMPLOYEE_ROLE],
        //     featureName: 'Home',
        //     icon: SVGIcons.HomeIcon(),
        //     link: `/${facilityURL}/student-home`
        // },
        // {
        //     title: translateThis("Tests"),
        //     pageHeader: translateThis("Tests"),
        //     id: 'tests',
        //     // allowOnly: [UNIVERSITY_ROLE, EMPLOYEE_ROLE],
        //     featureName: 'tests',
        //     icon: SVGIcons.GuardIcon(),
        //     link: `/${facilityURL}/create-practice`
        // },


    ].filter((item) => item.allowOnly.includes(getAuthRoleName()));

    const loginMenu = [
        {
            title: 'Profile',
            className: 'Profile',
            id: 'profile',
            icon: SVGIcons.ProfileIcon(),
            allowOnly: [UNIVERSITY_ROLE, UNIVERSITY_ADMIN_ROLE],
            link: `/${facilityURL}/profile`
        },
        {
            title: 'Profile',
            className: 'Profile',
            id: 'my-profile',
            icon: SVGIcons.ProfileIcon(),
            allowOnly: [STUDENT_ROLE],
            link: `/${facilityURL}/my-profile`
        },
        {
            title: 'Plans',
            className: 'plans',
            id: 'plans',
            icon: SVGIcons.PlansIcon(),
            allowOnly: [STUDENT_ROLE],
            link: `/${facilityURL}/plans`
        },
        // {
        //     title: 'Profile',
        //     className: 'Profile',
        //     allowOnly: [STUDENT_ROLE],
        //     icon: SVGIcons.ProfileIcon(),
        //     link: `/${facilityURL}/my-profile`
        // },

    ].filter((item) => item.allowOnly.includes(getAuthRoleName()));

    return (
        <>

            <DrawerHeader className='logo-sec-wrp mob-drawer-btn'>
                {myPortalInitialState.isDrawerOpen ? <>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <SVGIcons.BurgerMenuIcon />}
                    </IconButton></> : <><IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(myPortalInitialState.isDrawerOpen && { display: 'none' }),
                        }}
                        style={{ margin: '0', width: '100%' }}
                    >
                        <SVGIcons.BurgerMenuIcon />
                    </IconButton></>}


            </DrawerHeader>

            <Drawer variant="permanent" open={myPortalInitialState.isDrawerOpen} className={myPortalInitialState.isDrawerOpen ? 'drawer-open provider-drawer' : 'drawer-close provider-drawer'}>
                <div>
                    <DrawerHeader className='logo-sec-wrp'>
                        {myPortalInitialState.isDrawerOpen ? <>
                            <Link to={'#'} className='logo-img'><ProviderLogo/> </Link>
                            <Link to={'#'} className='mob-logo'><ProviderLogo/> </Link>
                            <IconButton onClick={handleDrawerClose} sx={{ display: 'none' }} >
                                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                            </IconButton></> : <>
                            <Link to={'#'} className='logo-img'><ProviderLogo/> </Link>
                            </>}


                    </DrawerHeader>
                    <List className='list-menu_1'>
                        {accessFiltered(newFeaturesCreateMenu).map((element, key) => (
                            // <ListItem disablePadding sx={{ display: 'block' }} key={key}>
                            <ListItem
                                className={myPortalInitialState.selectedNavItem === element.id ? 'active' : ''}
                                disablePadding sx={{ display: 'block' }}
                                onClick={() => onSelectNavItem(element)}
                                key={key}>
                                {/* <ListItem disablePadding sx={{ display: 'block' }} onClick={() => onSelectNavItem(element)} key={key}> */}
                                <Link to={element.link} onClick={handleDrawerClose}>
                                    <ListItemButton className={`${element.className} listButton`} sx={{ minHeight: 48, justifyContent: myPortalInitialState.isDrawerOpen ? 'initial' : 'center', px: 2.5, }} >
                                        <ListItemIcon sx={{ minWidth: 0, mr: myPortalInitialState.isDrawerOpen ? 3 : 'auto', justifyContent: 'center', }} >
                                            <span>{element.icon}</span>
                                        </ListItemIcon>
                                        {element.title ? <ListItemText primary={element.title} sx={{ opacity: myPortalInitialState.isDrawerOpen ? 1 : 1 }} /> : ''}
                                    </ListItemButton>
                                    {element.pcTitle && <ListItemText className='pc-title' primary={element.pcTitle} />}
                                </Link>
                            </ListItem>
                        ))}
                    </List>
                    <List className='list-menu_2'>
                        {/* {myNavItems.filter((item) => features[item.featureName] === true).map((element, key) => ( */}
                        {accessFiltered(myNavItems).map((element, key) => (
                            <ListItem
                                className={myPortalInitialState.selectedNavItem === element.id ? 'active' : ''}
                                disablePadding sx={{ display: 'block' }}
                                onClick={() => onSelectNavItem(element)}
                                key={key}>
                                <Link to={element.link} onClick={handleDrawerClose}>
                                    <ListItemButton className={`listButton ${element.id}`} sx={{ minHeight: 48, justifyContent: myPortalInitialState.isDrawerOpen ? 'initial' : 'center', px: 2.5, }} >
                                        <ListItemIcon sx={{ minWidth: 0, mr: myPortalInitialState.isDrawerOpen ? 3 : 'auto', justifyContent: 'center', }} >
                                            <span>{element.icon}</span>
                                        </ListItemIcon>
                                        <ListItemText primary={element.title} sx={{ opacity: myPortalInitialState.isDrawerOpen ? 1 : 1 }} />
                                    </ListItemButton>
                                </Link>

                            </ListItem>
                        ))}
                    </List>
                </div>
                <div>
                    <Divider />
                    <List className='list-menu_3'>
                        {loginMenu.map((element, key) => (
                            <ListItem className={myPortalInitialState.selectedNavItem === element.id ? 'active' : ''}
                                disablePadding sx={{ display: 'block' }}
                                onClick={() => onSelectNavItem(element)}
                                key={key}>
                                {/* <ListItemButton className={`${element.className} listButton`} sx={{ minHeight: 48, justifyContent: myPortalInitialState.isDrawerOpen ? 'initial' : 'center', px: 2.5, }} >
                                    <ListItemIcon sx={{ minWidth: 0, mr: myPortalInitialState.isDrawerOpen ? 3 : 'auto', justifyContent: 'center', }} >
                                        <span>{element.icon}</span>
                                    </ListItemIcon>
                                    {element.title ? <ListItemText primary={element.title} sx={{ opacity: myPortalInitialState.isDrawerOpen ? 1 : 0 }} /> : ''}
                                </ListItemButton> */}
                                <Link to={element.link} onClick={handleDrawerClose}>
                                    <ListItemButton className={`${element.className} listButton`} sx={{ minHeight: 48, justifyContent: myPortalInitialState.isDrawerOpen ? 'initial' : 'center', px: 2.5, }} >
                                        <ListItemIcon sx={{ minWidth: 0, mr: myPortalInitialState.isDrawerOpen ? 3 : 'auto', justifyContent: 'center', }} >
                                            <span>{element.icon}</span>
                                        </ListItemIcon>
                                        {element.title ? <ListItemText primary={translateThis(element.title)} sx={{ opacity: myPortalInitialState.isDrawerOpen ? 1 : 1 }} /> : ''}
                                    </ListItemButton>
                                </Link>
                            </ListItem>
                        ))}
                        
                        <ListItem disablePadding sx={{ display: 'block' }}>
                            <ListItemButton onClick={handleDrawerClose} className={`listButton theme-btn`} sx={{ minHeight: 48, justifyContent: myPortalInitialState.isDrawerOpen ? 'initial' : 'center', px: 2.5, }} >
                                <ListItemIcon sx={{ minWidth: 0, mr: myPortalInitialState.isDrawerOpen ? 3 : 'auto', justifyContent: 'center', }} >
                                    <span className='darkBG'>{SVGIcons.DarkBG()}</span>
                                    <span className='lightBG'>{SVGIcons.LightBG()}</span>
                                </ListItemIcon>
                                <ListItemText primary={<ThemeSwitchButton />} sx={{ opacity: myPortalInitialState.isDrawerOpen ? 1 : 1 }} />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding sx={{ display: 'block' }}>
                            <ListItemButton onClick={handleDrawerClose} className={`listButton logout`} sx={{ minHeight: 48, justifyContent: myPortalInitialState.isDrawerOpen ? 'initial' : 'center', px: 2.5, }} >
                                <ListItemIcon sx={{ minWidth: 0, mr: myPortalInitialState.isDrawerOpen ? 3 : 'auto', justifyContent: 'center', }} >
                                    <span>{SVGIcons.LogoutIcon()}</span>
                                </ListItemIcon>
                                <ListItemText primary={<LogoutButton />} sx={{ opacity: myPortalInitialState.isDrawerOpen ? 1 : 1 }} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </div>
            </Drawer>
            <Button className='closeBackBtn' onClick={handleDrawerClose} />
        </>

    );
}

export default UserDrawer;