import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { Link, useNavigate } from 'react-router-dom';
import { translateThis } from '../../../../helpers/language.helper';

import AppTable from '../../../../components/table/index';
import ApiCaller from '../../../../services/api/general';
import { showSnackbar } from '../../../../components/snackbar/index';
import SVGIcons from '../../../../assets/images/icons/svgIcons';
import AppIcons from '../../../../assets/images/icons';
import '../index.scss';

import { getCurrentFacilityURL } from '../../../../helpers/helper.functions';
import { DATE_FORMAT } from '../../../../constants';
import ConfirmDialog from '../../../../components/dialogs/confirm.dialog';
import AppPopOverMenu from '../../../../components/appPopOverMenu/index';





const ActiveExamTab = () => {
    const navigator = useNavigate();
    const apiCaller = new ApiCaller('universityExams/get-exams-by-university');
    const [isLoading, setIsLoading] = useState(true);
    const [dataArr, setDataArr] = useState([]);
    const [filteredDataArr, setFilteredDataArr] = useState([]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openPublishDialog, setOpenPublishDialog] = useState(false);
    const [selectedId, setSelectedId] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getDataArr()
        return () => {
            setDataArr([])
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getDataArr = () => {
        setIsLoading(true);
        apiCaller.getList()
            .then((data) => applyDataArr(data || []))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
     * To apply the filtered data
     * @param {Array} arr 
     */
    const applyDataArr = (arr = []) => {
        setDataArr(arr);
        setFilteredDataArr(arr);
    }

    /**
     * The string search
     * @param {String} searchTerm 
     */
    const onSearchData = (searchTerm = '') => {
        const searchedArr = dataArr.filter(obj => Object.values(obj).some(val => {
            const loweredString = Object.prototype.toString.call(val) === '[object String]' ? val.toLowerCase() : '';
            return loweredString.includes(searchTerm);
        }));
        setFilteredDataArr(searchedArr);
    }



    /**
     * Columns array to show 
     */
    const tableColumns = [
        {
            field: 'examName',
            headerName: 'Exam',
            minWidth: 175,
            flex: 2.5,
        },
        {
            field: 'date',
            headerName: 'Date',
            flex: 2,
            width: 230,
            maxWidth: 230,
            minWidth: 230,
            renderCell: ({ row }) => <>{row?.dateType === 'within range' ? <div>
                <span>Start: {row?.startDate ? dayjs(row?.startDate).format(DATE_FORMAT) : ''} {row?.startTime} </span><br />
                <span>End: {row?.endDate ? dayjs(row?.endDate).format(DATE_FORMAT) : ''} {row?.endTime} </span>
            </div> : <div><span>{row?.dateType}</span></div>}</>
        },
        {
            field: 'duration',
            headerName: 'Duration',
            flex: 1,
            width: 100,
            minWidth: 100,
            maxWidth: 100,
            headerAlign: 'center',
            align: 'center',
            // maxWidth: 130,
            renderCell: ({ row }) => <div>
                <span>{row?.duration} {row?.durationMetric} </span>
            </div>
        },
        {
            field: 'avgScorePercentage',
            headerName: 'Avg Score',
            minWidth: 100,
            maxWidth: 100,
            headerAlign: 'center',
            align: 'center',
            flex: 0.8,
            renderCell: ({ row }) => <>
                <span className='score'>{(row.avgScorePercentage && row.avgScorePercentage !== 'NaN') ? row.avgScorePercentage : 0}%</span>
            </>
        },
        {
            field: 'takersCount',
            headerName: 'Takers',
            flex: 1,
            minWidth: 100,
            headerAlign: 'center',
            align: 'center',
            maxWidth: 100,
        },
        {
            field: 'enrolled',
            headerName: 'Enrolled',
            flex: 1,
            minWidth: 135,
            maxWidth: 135,
            renderCell: ({ row }) => (row.whoCanTakeExam === 'Anyone with the link') ? 'Anyone with the link' : row.enrolled
        },
        {
            field: "numberOfQuestions",
            headerName: 'Q’s',
            flex: 1,
            minWidth: 90,
            maxWidth: 90,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: "isSelfAssessment",
            headerName: 'Self assess',
            flex: 1,
            minWidth: 135,
            maxWidth: 135,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: "status",
            headerName: 'Status',
            flex: 1,
            minWidth: 90,
            maxWidth: 90,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'idVal',
            headerName: 'Action',
            flex: 1,
            width: 100,
            minWidth: 100,
            maxWidth: 100,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            renderCell: ({ row }) => <>
                <AppPopOverMenu options={[
                    {
                        label: `${translateThis('View')}`,
                        onClick: () => navigator(`/${getCurrentFacilityURL()}/exam-logs/${row.id}`)
                    },
                    {
                        label: `${translateThis('Edit')}`,
                        onClick: () => navigator(`/${getCurrentFacilityURL()}/edit-exam/${row.id}`)
                    },
                    {
                        label: `${row.status === 'DRAFT' ? translateThis('Publish') : translateThis('Unpublish')}`,
                        onClick: () => {
                            setSelectedId(row.id)
                            setSelectedStatus(row.status)
                            setOpenPublishDialog(true)
                        }
                    },
                    {
                        label: `${translateThis('Delete')}`,
                        onClick: () => {
                            setSelectedId(row.id)
                            setOpenDeleteDialog(true)
                        }
                    }

                ]} />
                {/* <Link className='table-link-icon' to={`#`} type='button' onClick={() => null}>{SVGIcons.DuplicateIndicator()}</Link> */}
                {/* <Link className='table-link-icon' to={`#`} type='button' onClick={() => null}>{SVGIcons.Pencil2Icon()}</Link> */}
                {/* <Link className='table-link-icon' to={`#`} type='button' onClick={() => null}>{SVGIcons.DeleteBlueIcon()}</Link> */}
                <Link className='table-link-icon' to={`/${getCurrentFacilityURL()}/exam-logs/${row.id}`} type='button' onClick={() => null}>{SVGIcons.ChevronRightIcon()}</Link>
            </>
        },
    ]


    /**
       * Resource delete
       */
    const deleteData = () => {
        if (selectedId) {
            const apiCaller = new ApiCaller('universityTests');
            apiCaller.deleteData(selectedId)
                .then((res) => {
                    if (res.success) {
                        showSnackbar().success(`Exam deleted successfully`);
                    } else {
                        showSnackbar().failure(`Failed to delete exam`)
                    }
                })
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    setOpenDeleteDialog(false);
                    setSelectedId('');
                    getDataArr();
                })
        }

    }

    /**
    * Resource update
    */
    const updateData = () => {
        if (selectedId) {
            const apiCaller = new ApiCaller('universityTests');
            apiCaller.customPutData(selectedId, { status: selectedStatus === 'DRAFT' ? 'ACTIVE' : 'DRAFT' })
                .then((res) => {
                    if (res.success) {
                        showSnackbar().success(`Exam updated successfully`);
                    } else {
                        showSnackbar().failure(`Failed to update exam`)
                    }
                })
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    setOpenPublishDialog(false);
                    setSelectedId('');
                    setSelectedStatus('');
                    getDataArr();
                })
        }

    }




    return (
        <div className='main-category-content'>
            <div className='block-table-wrp prevAttempt-table f-wrp'>
                <div className='panel-table-sec provider-table PC-Table f-wrp'>
                    <AppTable
                        columns={tableColumns}
                        isLoading={isLoading}
                        data={dataArr}
                    />
                    {/* <Link className='label-button' to={`/${getCurrentFacilityURL()}/exam-view}`}>{translateThis('View')}</Link> */}
                    {isLoading ? <span className='loader main-loader'>{SVGIcons.LoaderIcon()}</span> :
                        <div className='mob-table-wrp childView prevTest f-wrp'>
                            <div className='mob-table-search' style={{marginTop: "20px"}}>
                                <input className='mob-table-input' placeholder={translateThis('Search')} onChange={(e) => onSearchData(e.target.value)} />
                            </div>
                            {filteredDataArr.length > 0 ? <ul>
                                {filteredDataArr.map((element) => (
                                    <li key={element.id}>
                                        <div className='each-sec-box' style={{ width: '65px' }}>
                                            <div className='status-with-btn'>
                                                <span className='score'>{(element.avgScorePercentage && element.avgScorePercentage !== 'NaN') ? element.avgScorePercentage : 0}%</span>
                                                <AppPopOverMenu icon={SVGIcons.StatusMobArrow()} options={[
                                                    // {
                                                    //     label: `${translateThis('View')}`,
                                                    //     onClick: () => navigator(`/${getCurrentFacilityURL()}/exam-logs/${element.id}`)
                                                    // },
                                                    {
                                                        label: `${translateThis('Edit')}`,
                                                        onClick: () => navigator(`/${getCurrentFacilityURL()}/edit-exam/${element.id}`)
                                                    },
                                                    {
                                                        label: `${element.status === 'DRAFT' ? translateThis('Publish') : translateThis('Unpublish')}`,
                                                        onClick: () => {
                                                            setSelectedId(element.id)
                                                            setSelectedStatus(element.status)
                                                            setOpenPublishDialog(true)
                                                        }
                                                    },
                                                    {
                                                        label: `${translateThis('Delete')}`,
                                                        onClick: () => {
                                                            setSelectedId(element.id)
                                                            setOpenDeleteDialog(true)
                                                        }
                                                    }
                                                ]} />
                                            </div>
                                            <span style={{ whiteSpace: 'nowrap' }}>{translateThis('Q’s')}: {element.numberOfQuestions}</span>
                                        </div>
                                        <div className='each-sec-box' style={{ width: '100%' }}>
                                            <div className='flex-wrp'>
                                                <span style={{ flex: '1', paddingBottom: '3px' }}><b>{element.examName ? element.examName : '-'}</b> {element.duration ? <b>({element?.duration} {element?.durationMetric})</b> : '-'}</span>
                                            </div>
                                            <div className='flex-wrp' style={{justifyContent: 'space-between', alignItems: 'flex-start'}}>
                                                {element?.dateType === 'within range' ? <div>
                                                    <span>Start: {element?.startDate ? dayjs(element?.startDate).format(DATE_FORMAT) : ''} {element?.startTime} </span><br />
                                                    <span>End: {element?.endDate ? dayjs(element?.endDate).format(DATE_FORMAT) : ''} {element?.endTime} </span>
                                                </div> : <div><span>{element?.dateType}</span></div>}
                                                <div>
                                                    <div className='flex-wrp'>
                                                        <span style={{ whiteSpace: 'nowrap' }}>{translateThis('Self assess')}: {element.isSelfAssessment}</span>
                                                    </div>
                                                    <div className='flex-wrp'>
                                                        <span>{translateThis('Takers')}: {element.takersCount}</span>
                                                    </div>
                                                    <div className='flex-wrp'>
                                                        <span>{translateThis('Enrolled')}: {element.enrolled}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Link className='full-li-btn' to={`/${getCurrentFacilityURL()}/exam-logs/${element.id}`} onClick={() => { navigator(`/${getCurrentFacilityURL()}/exam-logs/${element.id}`) }} />
                                    </li >
                                ))}
                            </ul> : <div className='empty-placeholder f-wrp'>
                                <div className='placeholder-con'>
                                    <img src={AppIcons.placeholderTable} alt='empty' />
                                    <h4>{translateThis('File not found')}</h4>
                                </div>
                            </div>
                            }
                        </div>
                    }
                </div>
                {openDeleteDialog ? <ConfirmDialog
                    isOpen={openDeleteDialog}
                    onClose={() => {
                        setOpenDeleteDialog(false);
                        setSelectedId('');
                    }}
                    title={`Delete Exam`}
                    className={'delete-popup'}
                    description={`Are you sure you want to delete this exam?\nThis action is permanent and cannot be undone.`}
                    okayButtonText={`Delete exam`}
                    onConfirm={deleteData}
                /> : null}

                {openPublishDialog ? <ConfirmDialog
                    isOpen={openPublishDialog}
                    onClose={() => {
                        setOpenPublishDialog(false);
                        setSelectedId('');
                        setSelectedStatus('');
                    }}
                    title={`update Exam`}
                    className={'delete-popup'}
                    description={`Are you sure you want to update this exam?\nThis action is permanent and cannot be undone.`}
                    okayButtonText={`update exam`}
                    onConfirm={updateData}
                /> : null}

            </div>
        </div>
    )
}
ActiveExamTab.propTypes = {}
export default ActiveExamTab;