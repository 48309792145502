/* eslint-disable global-require */
const AppIcons = {
  sampleIcon: require('./sample.ico'),
  loginBG: require('../login.jpg'),
  registerBG: require('../register.jpg'),
  logo: require('../Logo.png'),
  TagLine1: require('../TagLine1.png'),
  TagLine2: require('../TagLine2.png'),
  regLogo: require('../regLogo.png'),
  dashBoardLogo: require('../dashBoardLogo.png'),
  mobNavLogo: require('../mobNavLogo.png'),

  Question: require('../createNewQuestion/Question.png'),
  Subtopic: require('../createNewQuestion/Subtopic.png'),
  Topic: require('../createNewQuestion/Topic.png'),
  System: require('../createNewQuestion/System.png'),
  Subject: require('../createNewQuestion/Subject.png'),

  sub1: require('../subjectAdd/sub1.png'),
  sub2: require('../subjectAdd/sub2.png'),
  sub3: require('../subjectAdd/sub3.png'),
  sub4: require('../subjectAdd/sub4.png'),
  sub5: require('../subjectAdd/sub5.png'),
  sub6: require('../subjectAdd/sub6.png'),
  sub7: require('../subjectAdd/sub7.png'),

  anatomy: require('../topics/anatomy.png'),
  immunology: require('../topics/immunology.png'),

  Questions: require('../topicIcons/Questions.svg'),
  subjects: require('../topicIcons/Subjects.svg'),
  systems: require('../topicIcons/Systems.svg'),
  topics: require('../topicIcons/Topics.svg'),
  xray1: require('../xray1.png'),
  xray2: require('../xray2.png'),

  graph1: require('../graph1.png'),
  graph2: require('../graph2.png'),
  graph3: require('../graph3.png'),
  graph4: require('../graph4.png'),
  graph5: require('../graph5.png'),
  pie76: require('../pie76.png'),
  line48: require('../line48.png'),
  pie63: require('../pie63.png'),
  line52: require('../line52.png'),

  blue: require('./blue.png'),
  blue2: require('./blue2.png'),
  green: require('./green.png'),
  lytGreen: require('./lytgreen.png'),
  lytBlue: require('./lytblue.png'),
  lytBlue2: require('./lytblue2.png'),

  correct: require('./correct.png'),
  incorrect: require('./incorrect.png'),
  omitted: require('./omitted.png'),

  userDemo: require('../userDemo.png'),

  dashItem_1: require('../dashBoard/dashItem_1.png'),
  dashItem_2: require('../dashBoard/dashItem_2.png'),
  dashItem_3: require('../dashBoard/dashItem_3.png'),
  dashItem_4: require('../dashBoard/dashItem_4.png'),
  dashItem_5: require('../dashBoard/dashItem_5.png'),
  dashItem_6: require('../dashBoard/dashItem_6.png'),
  dashItem_7: require('../dashBoard/dashItem_7.png'),

  dashIcon_1: require('../dashBoard/dashIcon_1.png'),
  dashIcon_2: require('../dashBoard/dashIcon_2.png'),
  dashIcon_3: require('../dashBoard/dashIcon_3.png'),
  dashIcon_4: require('../dashBoard/dashIcon_4.png'),
  dashIcon_5: require('../dashBoard/dashIcon_5.png'),
  dashIcon_6: require('../dashBoard/dashIcon_6.png'),
  dashIcon_7: require('../dashBoard/dashIcon_7.png'),

  createQn: require('../createNewQuestion/Question.png'),
  createExam: require('../createNewQuestion/createExam.png'),
  createSub: require('../createNewQuestion/Subject.png'),
  createSys: require('../createNewQuestion/System.png'),
  createTopic: require('../createNewQuestion/Topic.png'),

  createQnBG: require('../createNewQuestion/createQn.png'),
  createExamBG: require('../createNewQuestion/createExamBG.png'),
  createSubBG: require('../createNewQuestion/createSub.png'),
  createSysBG: require('../createNewQuestion/createSys.png'),
  createTopicBG: require('../createNewQuestion/createTopic.png'),
  placeHolder: require('../placeHolder.png'),
  arrow: require('../arrow.png'),
  lineGraph: require('../lineGraph.png'),
  noExam: require('../noExam.png'),
  noClasses: require('../noClasses.png'),

  landingBanner: require('../LandingPage/banner.jpg'),
  landingLogo: require('../LandingPage/landingLogo.png'),
  logoBLK: require('../LandingPage/logoBLK.png'),

  landingOffer1: require('../LandingPage/offerSection/offer1.png'),
  landingOffer2: require('../LandingPage/offerSection/offer2.png'),
  landingOffer3: require('../LandingPage/offerSection/offer3.png'),
  landingOffer4: require('../LandingPage/offerSection/offer4.png'),
  landingOffer5: require('../LandingPage/offerSection/offer5.png'),
  landingOffer6: require('../LandingPage/offerSection/offer6.png'),
  landingOffer7: require('../LandingPage/offerSection/offer7.png'),
  landingOffer8: require('../LandingPage/offerSection/offer8.png'),
  landingOffer9: require('../LandingPage/offerSection/offer9.png'),
  landingOffer10: require('../LandingPage/offerSection/offer10.png'),

  profile: require('../LandingPage/profile.png'),
  profileGirl: require('../LandingPage/profileGirl.png'),
  leftArrow: require('../LandingPage/lft-arrow.png'),
  rightArrow: require('../LandingPage/ryt-arrow.png'),
  star: require('../LandingPage/star.png'),
  mobLogo: require('../LandingPage/mobLogo.png'),
  mobBanner: require('../LandingPage/mobBanner.png'),
  whiteLogo: require('../LandingPage/whiteLogo.png'),
  profileComplete: require('../profileComplete.png'),

  Text: require('./Text.png'),
  filter: require('./filter.png'),
  number: require('./number.png'),
  testMode: require('./testMode.png'),
  brush: require('./brush.png'),
  score: require('./score.png'),
  Time: require('./Time.png'),
  sub: require('./sub.png'),
  sys: require('./sys.png'),
  topic: require('./topic.png'),
  placeholderTable: require('../placeholderTable.png'),
  Difficulty: require('./Difficulty.png'),
  Date: require('./Date.png'),
  Description: require('./Description.png'),
  CalendarPlusIcon: require('./CalendarPlusIcon.png'),
  StatusLockIcon: require('./StatusLockIcon.png'),
  TimeTakenIcon: require('./TimeTakenIcon.png'),
  StudGroupIcon: require('./StudGroupIcon.png'),
  Students: require('./Students.png'),
  Attempts: require('./Attempts.png'),
  Introduction: require('./Introduction.png'),
  info: require('./info.png'),
  previewIcon: require('../previewIcon.png'),
  selfAssessment: require('../selfAssessment.png'),
  logoPlaceholder: require('../logoPlaceholder.png'),
  discard: require('../discard.png'),
  examCreated: require('../examCreated.png'),
  iconDemo: require('./iconDemo.png'),

  selfAssessPlaceholder: require('../selfAssessPlaceholder.png'),
  SelfAssess: require('./SelfAssess.png'),
  examPlaceholder: require('../examPlaceholder.png'),

  subIcon: require('../subscription.png'),

  RDlandingBanner: require('../RepublicaDominicana/banner.jpg'),
  RDlandingLogo: require('../RepublicaDominicana/landingLogo.png'),
  // RDlogoBLK: require('../RepublicaDominicana/logoBLK.png'),

  generalLandingBanner: require('../generalHome/banner.png'),
  bannerFooter: require('../generalHome/bannerFooter.png'),

  abtGlobe: require('../generalHome/abtGlobe.png'),
  abtClock: require('../generalHome/abtClock.png'),
  checkCircle: require('../generalHome/checkCircle.png'),
  offerLaptop: require('../generalHome/offerLaptop.png'),
  appDemo: require('../generalHome/appDemo.png'),
  whatImg: require('../generalHome/whatImg.png'),
  testimonialBGIcon: require('../generalHome/testimonialBGIcon.png'),
  starBlk: require('../generalHome/starBlk.png'),

  argentina: require('../generalHome/argentina.png'),
  Bolivia: require('../generalHome/Bolivia.png'),
  Ecuador: require('../generalHome/Ecuador.png'),
  peru: require('../generalHome/peru.png'),
  repDom: require('../generalHome/repDom.png'),
  usa: require('../generalHome/usa.png'),
  aboutImg: require('../generalHome/aboutImg.png'),
  classCoverImg: require('../classCoverImg.png')



};
export default AppIcons;
/* eslint-enable global-require */
